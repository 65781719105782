<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after"> {{ getTitle }}</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <!-- 页签 -->
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="申请详情"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon">
        <div class="edit-content">
          <div class="edit-content-left">
            <el-form ref="form" :model="form" :rules="rules" style="margin-bottom: 20px">
              <el-descriptions direction="vertical" :column="6" border style="width: 100%">
                <el-descriptions-item
                  label="申请编号"
                  v-if="options.list_s == 2 || options.list_s == 3 || options.list_s == 4"
                >
                  <el-form-item prop="expenseNumber" ref="expenseNumber">
                    {{ form.expenseNumber }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="申请人">
                  <el-form-item prop="createName" ref="createName">
                    {{ form.createName }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="费用所属部门">
                  <el-form-item prop="deptList" ref="deptList">
                    {{ form.deptName }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="申请日期">
                  <el-form-item prop="taskDate" ref="taskDate">
                    {{ form.taskDate | dateFormat }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="费用所属单位">
                  <el-form-item prop="companyType" ref="companyType">
                    <el-row class="demo-autocomplete">
                      <el-col>
                        <span v-if="options.list_s == 2 || options.list_s == 3">
                          {{ getCompanyTypeName(form.companyType) }}
                        </span>
                        <Dictionary
                          v-else
                          v-model="form.companyType"
                          code="COMPANY_TYPE"
                          placeholder="请选择费用所属单位"
                        />
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-descriptions-item>
              </el-descriptions>
            </el-form>

            <el-button
              v-show="![2, 3, 4].includes(options.list_s)"
              type="primary"
              class="addButton"
              @click="addRow()"
              icon="el-icon-plus"
              >添加申请明细</el-button
            >

            <el-form class="column3 form_box table" label-position="top" style="width: 100%">
              <el-table :data="tableData" id="table" :row-style="{ height: '80px' }">
                <!-- 删除 -->
                <el-table-column label="操作" v-if="![2, 3, 4].includes(options.list_s)" width="70">
                  <template slot-scope="scope">
                    <i
                      class="el-icon-remove-outline iconCost"
                      @click="handleRemove(scope.$index, tableData)"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="taskDate"
                  label="日期"
                  width="250"
                  :render-header="addRedStar"
                  :show-overflow-tooltip="false"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      :readonly="options.list_s == 2 || options.list_s == 3"
                      v-model="scope.row.taskDate"
                      type="date"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                    <div class="color_r" v-if="!scope.row.taskDate && status == 1">日期必填</div>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="cost"
                  label="申请金额(元)"
                  width="250"
                  :render-header="addRedStar"
                  :show-overflow-tooltip="false"
                >
                  <template slot-scope="scope">
                    <span v-if="options.list_s == 2 || options.list_s == 3">{{
                      scope.row.cost
                    }}</span>
                    <el-input
                      v-else
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      placeholder="请输入"
                      v-model="scope.row.cost"
                    >
                    </el-input>
                    <div class="color_r" v-if="scope.row.cost <= 0 && status == 1">
                      金额不能为零
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  scoped-slot
                  prop="contentDetail"
                  :label="'事项说明'"
                  :render-header="addRedStar"
                  :show-overflow-tooltip="false"
                >
                  <template slot-scope="scope">
                    <el-input
                      readonly
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 5 }"
                      v-if="options.list_s == 2 || options.list_s == 3"
                      v-model="scope.row.contentDetail"
                    ></el-input>
                    <el-input
                      v-else
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 5 }"
                      placeholder="请输入"
                      v-model="scope.row.contentDetail"
                    ></el-input>
                    <div class="color_r" v-if="!scope.row.contentDetail && status == 1">
                      事项说明必填
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>

            <!-- 合计内容 -->
            <el-descriptions title="" :column="2" border>
              <el-descriptions-item label="合计（元）" labelStyle="width:15%">
                {{ amount }}
              </el-descriptions-item>
              <el-descriptions-item label="金额大写" labelStyle="width:15%">
                {{ amount | numberParseChina }}
              </el-descriptions-item>
            </el-descriptions>
          </div>

          <!-- 意见区 -->
          <div class="edit-content-right">
            <OpinionAreaRight></OpinionAreaRight>
            <!-- <OpinionArea
              :title="'预申请意见'"
              :procInstId="this.options.processInstId"
            ></OpinionArea> -->
          </div>
        </div>

        <!-- 按钮区域 -->
        <!-- 填报详情按钮 -->
        <div class="fullScreenOperation" v-if="detailType == 0">
          <el-button
            v-if="![2, 3, 4].includes(options.list_s)"
            type="success"
            :loading="loading"
            @click="save"
          >
            保存并提交
          </el-button>
          <el-button
            v-if="![2, 3, 4].includes(options.list_s)"
            type="success"
            :loading="loading"
            @click="tempSave"
          >
            暂存
          </el-button>
          <el-button v-if="form.status == '10'" type="success" :loading="loading" @click="reject()">
            撤回
          </el-button>
          <el-button
            v-if="options.list_s == 3"
            type="success"
            :loading="loading"
            @click="exporting"
          >
            导出
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>

        <!-- 审批详情按钮 -->
        <div class="fullScreenOperation" v-else-if="detailType == 1">
          <el-button type="success" :loading="loading" @click="showDialogForm(1)"> 通过 </el-button>
          <el-button type="success" :loading="loading" @click="showDialogForm(2)">
            不通过
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>

        <!-- 只有返回 -->
        <div class="fullScreenOperation" v-else>
          <el-button type="info" @click="re">返回</el-button>
        </div>
      </div>
    </div>

    <EditDialog :isShow.sync="dialogFormVisible" @submit="submit">
      <div slot="title" class="title">审批意见</div>
      <div slot="content" class="content">
        <div class="text-center-btm">
          <el-form :model="dg_form" ref="form_d">
            <el-form-item label="" prop="opinion">
              <el-input
                v-model="dg_form.opinion"
                @input="$forceUpdate()"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    OpinionAreaRight: () => import('@/components/OpinionAreaRight.vue'),
  },

  inject: ['re'],

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    getTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),

    amount() {
      const sum = this.tableData.reduce((total, item) => (total += Number(item.cost)), 0)
      return sum
    },
  },

  data() {
    return {
      detailType: '', //* detailType标识详情类型，0表示填报详情，1表示审批详情
      showEditDialog: false,
      loading: false,
      activeName: '0',
      status: 0,
      form: {
        companyType: 'hui_tu', //公司单位
        expenseNumber: '', //申请编号
        userName: '', //发起人
        deptName: '', //部门
      },
      rules: {
        companyType: [
          { required: true, message: '请选择费用所属单位', trigger: ['blur', 'change'] },
        ],
      },
      tableData: [], //明细表格数据
      rowDeleteList: [], //被删除的明细
      // 时间选择器
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now() - 8.64e6
      //   },
      // },
      // 审批意见框弹出
      dialogFormVisible: false,
      dg_form: {},
      companyTypeList: [],
    }
  },

  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },

    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.init()
        }
      },
    },

    // dialogFormVisible: {
    //   immediate: true, //初始化立即执行
    //   handler: function(newVal) {
    //     if (!newVal) {
    //       this.dg_form.opinion = ''
    //     }
    //   },
    // },
  },

  methods: {
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 通过dictVal获取dictName
    getCompanyTypeName(val) {
      if (val) {
        const findResult = this.companyTypeList.find(item => item.dictVal == val)
        if (findResult) {
          return findResult.dictName
        } else {
          return ''
        }
      }
    },

    init() {
      this.getCompanyList()
      this.form = {}
      this.tableData = []
      this.detailType = this.options.detailType
      if (this.options.id) {
        this.loading = true
        this.$api.publicSpendingExpense
          .selectDetailById(this.options.id)
          .then(res => {
            this.form = res.data
            this.tableData = res.data.detailList
            this.$refs.form.clearValidate() //清楚表单验证提示
            this.loading = false
            // 拿到详情后获取意见列表
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        const str = this.userInfo.deptNames.split(',')
        this.form = {
          createName: this.userInfo.userName,
          companyType: 'hui_tu',
          deptId: this.userInfo.deptIdList[0],
          deptName: str[0],
          taskDate: new Date().getTime(),
        }
        this.tableData.push({
          taskDate: '',
          cost: 0,
          contentDetail: '',
        })
      }
    },

    addRedStar(h, { column }) {
      if (this.detailType == 0 && ![2, 3, 4].includes(this.options.list_s)) {
        return [h('span', { style: 'color: red' }, '*'), h('span', ' ' + column.label)]
      } else {
        return [h('span'), h('span', column.label)]
      }
    },

    // 添加一行
    addRow() {
      let obj = {
        taskDate: '',
        content: '',
        cost: 0,
        contentDetail: '',
      }
      if (this.form.id) {
        let publicSpendingExpenseApplyId = this.form.id
        obj.publicSpendingExpenseApplyId = publicSpendingExpenseApplyId
      }
      this.tableData.push(obj)
    },

    // 删除当前行
    handleRemove(index, tableData) {
      if (tableData[index].id) {
        tableData[index].deleteFlag = 1
        this.rowDeleteList.push(tableData[index])
      }
      tableData.splice(index, 1)
    },

    // *提交
    save() {
      this.status = 1
      //处理验证逻辑，status为1则是提交
      let ruleTips = false
      this.tableData.forEach(m => {
        //验证
        if (!m.taskDate || m.taskDate == null) {
          ruleTips = true
        }
        if (!m.cost || m.cost <= 0) {
          ruleTips = true
        }
        if (!m.contentDetail || m.contentDetail == null) {
          ruleTips = true
        }
      })
      if (ruleTips) {
        this.$forceUpdate()
        return
      }
      //处理提交给后端的格式
      let new_arr = []
      this.tableData.forEach((m, i) => {
        new_arr.push(m)
      })
      //默认获取当天
      let cond = {
        ...this.form,
        taskDate: new Date().getTime(),
        detailList: new_arr,
      }
      this.editDialogLoading = true
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.$api.publicSpendingExpense
            .startProcess(cond)
            .then(res => {
              this.editDialogLoading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              console.log('错误：', err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },

    // *暂存
    tempSave() {
      this.status = 0
      //处理提交给后端的格式
      let new_arr = []
      this.tableData.forEach((m, i) => {
        new_arr.push(m)
      })
      //默认获取当天
      let cond = {
        ...this.form,
        taskDate: new Date().getTime(),
        detailList: new_arr,
      }
      this.editDialogLoading = true
      //* 如果有id且当前状态是暂存，则走暂存编辑接口
      if (this.form.id && this.form.status == 0) {
        //todo 被删除的明细如何提交
        this.$api.publicSpendingExpense
          .undeterminedEdit(cond)
          .then(res => {
            this.editDialogLoading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log('错误：', err)
          })
      } else {
        this.$api.publicSpendingExpense
          .tempAdd(cond)
          .then(res => {
            this.editDialogLoading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log('错误：', err)
          })
      }
    },

    //todo 撤回
    reject() {},

    // *审核弹框
    showDialogForm(status) {
      this.dg_form.opinion = ''
      this.dialogFormVisible = true
      this.dg_form.status = status
    },

    submit() {
      if (!this.dg_form.opinion && this.dg_form.status == 2) {
        this.$message('审批意见不能为空！')
        return
      }
      let query = {
        id: this.form.id,
        opinion: this.dg_form.opinion,
      }
      // 点击通过
      if (this.dg_form.status == 1) {
        query.opinion = !query.opinion.trim() ? '通过' : query.opinion
        this.editDialogLoading = true
        this.$api.publicSpendingExpense
          .disposeTask(query)
          .then(res => {
            this.editDialogLoading = false
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.editDialogLoading = false
            console.log(err)
          })
      } else if (this.dg_form.status == 2) {
        // todo 审批不通过
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.addButton {
  margin-bottom: 10px;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}

b {
  font-size: 19px;
  color: black;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}

.bg_div {
  border: 1px solid #eee;
  margin-bottom: 10px;
}

.color_r {
  color: #f00;
}

.row_css {
  font-size: 14px;
  color: #909399;
  vertical-align: inherit;
  padding-left: 5px;
}

/deep/ .el-table .cell {
  white-space: pre-line;
  width: 100% !important;
}

.upload-center {
  display: flex;
  justify-content: center;
}

.el-form-item {
  margin-bottom: 0px !important;
}

.edit-content {
  display: flex;
  .edit-content-left {
    flex: 2;
  }
  .edit-content-right {
    flex: 1;
  }
}
</style>
